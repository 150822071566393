<template>
  <section class="list container">
    <div id="results">
      <div class="preheader d-flex justify-content-between" v-if="title">
        <h2>
          {{ title }}
          <span class="i-tooltip" v-b-tooltip.hover :title="tooltip">
            <i-tooltip />
          </span>
        </h2>
        <ToggleArrow v-if="toggle === true" @click="toggleMe = !toggleMe" section="toggleMe" :status="toggleMe">
        </ToggleArrow>
      </div>
      <div v-if="toggleMe && items.length === 0" style="margin-left: 50px">
        <header v-if="toggleMe" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            {{ i18n['data-table-conventions'].tcNoRecordsFound }}
          </div>
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <button class="btn btn-primary flex-0 d-block w-100-sd" v-if="addItems.display"
              @click="triggerAction(addItems.action)">
              {{ addItems.text }}
            </button>
          </div>
        </header>
      </div>
      <div v-if="items.length > 0">
        <header v-if="toggleMe && !hideHeader" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <a class="btn btn-dark flex-0 mr-2" v-if="csvUrl.length > 0"
              @click.prevent="downloadReport(csvUrl, 'ConventionItems', 'csv')">{{
              i18n['data-table-conventions'].tcExportCSV }}</a>
            <a class="btn btn-dark flex-0 mr-2" v-if="pdfUrl.length > 0"
              @click.prevent="downloadReport(pdfUrl, 'ConventionItems', 'pdf')">{{
              i18n['data-table-conventions'].tcExportPDF }}</a>
            <b-form-input v-model="filter" @input="handleSearchTermChange"
              :placeholder="i18n['data-table-conventions'].tcSearch"
              class="search w-100-sd mw-100-sd mb-3 mb-sm-0 d-block w-100-sd order-1 order-sm-2"></b-form-input>
          </div>
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <button class="btn btn-primary flex-0 d-block w-100-sd" v-if="addItems.display"
              @click="triggerAction(addItems.action)">
              {{ addItems.text }}
            </button>
          </div>
        </header>
        <main v-if="toggleMe" class="table-responsive">
          <b-table class="g-table" :fields="fields" :items="items" :per-page="perPage" :current-page="currentPage"
            :filter="filter" :sort-by.sync="sortByForTable" :sort-desc.sync="sortDesc" sort-icon-left small>
            <div class="d-inline-flex align-items-start" slot="HEAD_Media" slot-scope="data" @click="log(data)">
              <b-button v-if="data.field.cTooltip" id="button-1" class="tooltip-button mr-1" style="top: -7px">?
              </b-button>
              <b-tooltip v-if="data.field.cTooltip" target="button-1" :title="data.field.cTooltip"></b-tooltip>
              {{ data.label }}
            </div>
            <template v-slot:cell(event_active_flag)="data">
              <b-form-checkbox v-model="data.value" name="check-button" switch @change="toggleActive(data)">
                {{ data.value ? i18n['data-table-conventions'].tcActive : i18n['data-table-conventions'].tcInactive }}
              </b-form-checkbox>
            </template>
            <template v-slot:cell(event_key)="data">
              <div class="service-action-bar">
                <div @click="setConventionKey(data)">
                  <ServiceEdit />
                </div>
                <div @click="deleteConvention(data)">
                  <ServiceDelete />
                </div>
              </div>
            </template>
            <template v-slot:cell(blz_key)="data">
              <div class="service-action-bar">
                <div @click="setBlitzKey(data)">
                  <ServiceEdit />
                </div>
                <div @click="deleteBlitz(data)">
                  <ServiceDelete />
                </div>
              </div>
            </template>
          </b-table>
        </main>
        <footer v-if="toggleMe" class="d-flex align-items-center justify-content-between">
          <div class="prev" @click="prevPage" :class="{ hidden: this.currentPage == 1 }">
            <i-arrow-left />
          </div>
          <nav class="d-flex align-items-center justify-content-center">
            <div class="show d-flex align-items-center justify-content-end">
              <div class="lower">{{ i18n['data-table-conventions'].tcShow }}</div>
              <button :class="{ active: this.perPage == 20 }" @click="updateNumber(20)">20</button>
              <button :class="{ active: this.perPage == 50 }" @click="updateNumber(50)">50</button>
              <button :class="{ active: this.perPage == 80 }" @click="updateNumber(80)">80</button>
            </div>
            <div class="sep"></div>
            <div class="pagination justify-self">
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" :hide-goto-end-buttons="true"
                @change="pageChanged"></b-pagination>
            </div>
          </nav>
          <div class="next" @click="nextPage" :class="{
            hidden: Math.ceil(this.items.length / this.perPage) == this.currentPage,
          }">
            <i-arrow-right />
          </div>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import iArrowLeft from '@/assets/svgs/arrow-left.vue'
import iArrowRight from '@/assets/svgs/arrow-right.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import ServiceDelete from '@/assets/svgs/service-card-delete.vue'
import ServiceEdit from '@/assets/svgs/service-card-edit.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'

export default {
  name: 'data-table-conventions',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'data-table-conventions': {
            tcActive: 'Active',
            tcCancel: 'Cancel',
            tcExportCSV: 'Export CSV',
            tcExportPDF: 'Export PDF',
            tcInactive: 'Inactive',
            tcNoRecordsFound: 'No records were found.',
            tcOk: 'Ok',
            tcOkayToDeleteEvent: 'This event will be deleted. Is that Ok?',
            tcSearch: 'Search',
            tcShow: 'Show',
          },
        }
      },
    },
    fields: {
      type: Array,
      default: [],
    },
    items: {
      type: Array,
      default: [],
    },
    csvUrl: {
      type: String,
      default: '',
    },
    pdfUrl: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    sortBy: {
      type: String,
      default: 'Name',
    },
    addItems: {
      type: Object,
      default: {
        text: 'Add',
        display: true,
        action: '#',
      },
    },
    hideHeader: { type: Boolean, default: false },
    title: String,
    toggle: Boolean,
  },
  data() {
    return {
      // Table Settings
      sortByForTable: '',
      sortDesc: false,
      perPage: 20,
      currentPage: 1,
      filter: null,
      number: 20,
      pag: '',
      toggleMe: true,
    }
  },
  methods: {
    ...mapActions({
      setSearchTerm: 'search/setSearchTerm',
      setSelectedConventionKey: 'user/setSelectedConventionKey',
    }),
    pageChanged() {
      document.getElementById('results').scrollIntoView()
    },
    updateNumber(num) {
      this.perPage = num
    },
    prevPage() {
      if (this.currentPage == 1) {
        return
      }
      this.currentPage--
      document.getElementById('results').scrollIntoView()
    },
    nextPage() {
      if (Math.ceil(this.items.length / this.perPage) == this.currentPage) {
        return
      }
      this.currentPage++
      document.getElementById('results').scrollIntoView()
    },
    triggerAction(action) {
      this.$emit(action)
    },
    emitAction(action, data) {
      this.$emit(action, data)
    },
    log(item) { },
    type(val) {
      return typeof val
    },
    notUndefined(val) {
      return !val ? '' : val
    },
    async setConventionKey(data) {
      this.$emit('editEvent', data.item.event_key)
    },
    async setBlitzKey(data) {
      this.$emit('editEvent', data.item.blz_key)
    },
    async deleteConvention(data) {
      this.$swal({
        icon: 'question',
        confirmButtonText: this.i18n['data-table-conventions'].tcOk,
        cancelButtonText: this.i18n['data-table-conventions'].tcCancel,
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.i18n['data-table-conventions'].tcOkayToDeleteEvent,
      }).then((result) => {
        if (result.value) {
          this.$emit('deleteEvent', data.item.event_key)
        }
      })
    },
    async deleteBlitz(data) {
      this.$swal({
        icon: 'question',
        confirmButtonText: this.i18n['data-table-conventions'].tcOk,
        cancelButtonText: this.i18n['data-table-conventions'].tcCancel,
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.i18n['data-table-conventions'].tcOkayToDeleteBlitz,
      }).then((result) => {
        if (result.value) {
          this.$emit('deleteEvent', data.item.blz_key)
        }
      })
    },
    async locationStorePlacementKeyAndRedirect(data) {
      await this.setSelectedLocationKey(data.item.plf_org_key)
      await this.setSelectedPlacementDateKey(data.item.mtg_key)
      this.$router.push({ name: 'placement-details' })
    },
    individualStoreKeyAndRedirect(data) {
      this.setSelectedIndividualKey(data)
      this.$router.push('/programs/mb/member-profile/')
    },
    toggleActive(data) {
      setTimeout(() => {
        // this is to give the change event a tick to update the value
        this.emitAction('activeToggleEvent', data)
      }, 0)
    },
    async handleSearchTermChange() {
      await this.setSearchTerm({ conventionsTerm: this.filter })
    },
  },
  computed: {
    ...mapGetters({
      searchTerms: 'search/searchTerms',
      userSelectedMeetingKey: 'user/userSelectedMeetingKey',
    }),
    rows() {
      return this.items ? this.items.length : 0
    },
  },
  created() {
    this.sortByForTable = this.sortBy
    this.perPage = constantData.per_page_default
    this.filter = this.searchTerms.conventionsTerm
  },
  components: {
    iArrowLeft: iArrowLeft,
    iArrowRight: iArrowRight,
    iTooltip: iTooltip,
    ServiceDelete: ServiceDelete,
    ServiceEdit: ServiceEdit,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/views/ChurchList.scss';

.preheader {
  padding: 30px 32px 15px;
  align-items: center;

  @include breakpoint(sm) {
    padding-left: 23px;
    padding-right: 23px;
  }

  h2 {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4;
    line-height: 44px;
    text-transform: uppercase;

    @include breakpoint(sm) {
      padding-top: 0;
    }
  }
}

.lower {
  text-transform: lowercase;
}

.i-tooltip {
  vertical-align: super;

  svg path {
    fill: #000 !important;
  }
}

.service-action-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  >div {
    margin-right: 10px;

    &:hover {
      opacity: 0.6;
    }

    svg {
      cursor: pointer;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
</style>
